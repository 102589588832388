/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchProvider } from '@faststore/sdk'
import { graphql, navigate } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import ProductGalleryV2 from 'src/components/sections/ProductGalleryV2'
import SROnly from 'src/components/ui/SROnly'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import type { GetServerDataProps, PageProps } from 'gatsby'
import type { SearchState } from '@faststore/sdk'
import type { SearchResult } from 'src/components/sections/ProductGalleryV2/types'
import type {
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables,
} from '@generated/graphql'
import Breadcrumb from 'src/components/ui/Breadcrumb'
import axios from 'axios'
import { getStoreConfig } from 'src/store-config/get-config'
import getSearchServerSidedata from 'src/utils/plp/getSearchServerSidedata'
import type { PlacementResponse } from 'src/utils/placements/fetchPlacementData'
import getPlacementsServerData from 'src/utils/placements/getPlacementsServerData'

const storeConfig = getStoreConfig()

type ServerData = {
  serverData: {
    searchParams: SearchState
    searchResult: SearchResult
    redirect: string | null
    placementData: PlacementResponse & { searchTerm?: string }
  }
}

export type Props = PageProps<
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables
> &
  ServerData

export default function SearchPage(props: Props) {
  const {
    serverData: { searchParams, searchResult, redirect, placementData },
  } = props

  const hasRedirect = !!redirect

  if (hasRedirect) {
    return navigate((props as any).serverData.redirect as string)
  }

  if (!props || hasRedirect) {
    return null
  }

  const {
    data: { site },
  } = props

  const title = 'Search Results | FastStore'
  const EVENT_API_BASE_PATH = 'https://sp.vtex.com'

  if (!searchParams) {
    return null
  }

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <GatsbySeo
        language="pt-br"
        title={title}
        description={site?.siteMetadata?.description ?? ''}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
        linkTags={[
          {
            rel: 'preconnect',
            href: EVENT_API_BASE_PATH,
          } as any,
          {
            rel: 'preconnect',
            href: 'https://decathlonproqa.vteximg.com.br',
          } as any,
          {
            rel: 'preconnect',
            href: 'https://decathlonstore.vtexassets.com',
          } as any,
          {
            rel: 'dns-prefetch',
            href: EVENT_API_BASE_PATH,
          } as any,
          {
            rel: 'dns-prefetch',
            href: 'https://decathlonproqa.vteximg.com.br',
          } as any,
          {
            rel: 'dns-prefetch',
            href: 'https://decathlonstore.vtexassets.com',
          } as any,
        ]}
      />

      <SROnly as="h1" text={title} />
      <div className="flex items-center max-w-[1280px] w-full my-0 mx-auto px-4 mb-6">
        <Breadcrumb
          type="single"
          breadcrumbList={[
            {
              item: `/pesquisa?q=${searchParams.term}`,
              name: searchParams.term ?? '',
              position: 1,
            },
          ]}
        />
      </div>

      <ProductGalleryV2
        title={searchParams.term ?? ''}
        type="search"
        pageType="search"
        searchResult={searchResult}
        placementData={placementData}
      />
    </SearchProvider>
  )
}

export const querySSG = graphql`
  query SearchPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`

export const getServerData = async (props: GetServerDataProps) => {
  const CACHE_CONTROL = `public, max-age=1800, s-maxage=1800, must-revalidate`

  const { query } = props
  const searchTerm = query?.q as string

  try {
    const { data } = await axios.get(
      `https://${storeConfig.accountName}.myvtex.com/_v/api/intelligent-search/product_search`,
      {
        params: {
          query: searchTerm,
        },
      }
    )

    const { searchParams, searchResult } = await getSearchServerSidedata({
      query,
      searchTerm,
    })

    const placementData = await getPlacementsServerData({
      searchTerm,
    })

    return {
      status: 200,
      props: {
        redirect: data?.redirect ?? null,
        searchResult,
        searchParams,
        placementData: { ...placementData, searchTerm },
      },
      headers: {
        'cache-control': CACHE_CONTROL,
      },
    }
  } catch (err) {
    console.error(err)

    return {
      status: 500,
      props: {},
      headers: {
        'cache-control': 's-maxage=31536000, stale-while-revalidate',
      },
    }
  }
}
